<template>
  <div>
    <el-card>
      <el-input
        v-model="username"
        placeholder="请输入要查找的联系人"
      ></el-input>
      <el-input v-model="mobile" placeholder="请输入要查找的手机号"></el-input>
      <el-button type="primary" @click="query">查询</el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="laboratoryCode"
          label="实验室编号"
          min-width
        ></el-table-column>
        <el-table-column
          prop="unitName"
          label="单位"
          width="250"
        ></el-table-column>
        <el-table-column prop="laboratoryName" label="科室"></el-table-column>
        <el-table-column
          prop="qualityControlPerson"
          label="联系人"
        ></el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column
          prop="qualityContPerMail"
          label="联系人邮箱"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="qualityContPerAddress"
          label="地址"
          width="400"
        ></el-table-column>
        <el-table-column prop="password" label="密码"></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button type="primary" @click="putOutBlack">拉出黑名单</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      mobile: "",
      total: 0,
      // 选中的用户id
      checkUserid: [],
      currPage: 1,
      totalSize: 10,
      // 黑名单列表数据
      tableData: [],
    };
  },
  computed: {},
  created() {
    this.getBlackList();
  },
  methods: {
    // 获取用户列表
    async getBlackList() {
      const { data: res } = await this.$http.post("sys/user/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        status: "0",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      console.log(res);
    },
    // 查询用户
    async query() {
      const { data: res } = await this.$http.post("sys/user/list", {
        username: this.username,
        mobile: this.mobile,
        status: "0",
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 拉出黑名单
    async putOutBlack() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      } else {
        const confirm = await this.$confirm("确定将用户拉出黑名单吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          const { data: res } = await this.$http.post(
            "sys/user/updateUserStatus",
            {
              id: this.checkUserid,
              status: "1",
            }
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("成功拉出黑名单");
            this.getBlackList();
          }
        }
      }
    },
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getBlackList();
    },
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getBlackList();
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.checkUserid = val.map((item) => {
        return item.id;
      });
      console.log(this.checkUserid);
    },
  },
};
</script>
<style lang="less" scoped>
.el-button {
  margin: 20px;
}
.el-input {
  width: 200px;
  margin-right: 30px;
}
</style>
