<template>
  <div>
    <el-card>
      <div class="title">
        <div>
          <span>默认查询:</span>
          <el-select v-model="queryDate" placeholder="请选择">
            <el-option
              v-for="item in queryList"
              :key="item.index"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>项目名称:</span>
          <el-select
            v-model="prodName"
            multiple
            placeholder="请选择"
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in proNameList"
              :key="index"
              :label="item.prodName"
              :value="item.prodName"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>试剂品牌：</span>
          <el-select
            v-model="reagentBrand"
            multiple
            placeholder="请选择"
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in reagentBrandList"
              :key="index"
              :label="item.reagentBrand"
              :value="item.reagentBrand"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>检测方法:</span>
          <el-select
            v-model="checkMethod"
            multiple
            placeholder="请选择"
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in checkMethodList"
              :key="index"
              :label="item.checkMethod"
              :value="item.checkMethod"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>操作方法:</span>
          <el-select
            v-model="operationMethod"
            multiple
            placeholder="请选择"
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in operationMethodList"
              :key="index"
              :label="item.operationMethod"
              :value="item.operationMethod"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>质控品编号:</span>
          <el-select
            v-model="controlMaterialCode"
            multiple
            placeholder="请选择"
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in controlMaterialCodeList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>选择数据类型:</span>
          <el-select
            v-model="updata"
            placeholder="请选择"
            clearable
            :disabled="isable"
          >
            <el-option
              v-for="(item, index) in updateList"
              :key="index"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div><el-button type="primary" @click="query">查询</el-button></div>
      </div>
      <el-table
        class="tbl"
        border
        :data="projectList"
        :span-method="objectSpanMethod"
      >
        <el-table-column prop="prodNameResult" label="项目名称" width="400">
        </el-table-column>
        <el-table-column prop="reagentBrand" label="试剂品牌" width="200">
        </el-table-column>
        <el-table-column prop="checkMethod" label="检测方法" width="200">
        </el-table-column>
        <el-table-column prop="operationMethod" label="操作方法" width="200">
        </el-table-column>
        <el-table-column
          prop="controlMaterialCode"
          label="质控品编号"
          width="120"
        >
        </el-table-column>
        <el-table-column label="PT允许范围" width="280" class="pt">
          <template slot-scope="scope">
            <el-input
              type="text"
              v-model="scope.row.ptValue"
              @blur="PTone(scope.row.ptValue)"
            ></el-input>
            <span class="line">-</span>
            <el-input
              type="text"
              v-model="scope.row.ptValue2"
              @blur="PTtwo(scope.row.ptValue2)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="靶值" width="200">
          <template slot-scope="scope">
            <el-input
              type="text"
              v-model="scope.row.targetValue"
              @blur="targetAll(scope.row.targetValue)"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="btn">
        <el-button type="primary" @click="update">更新数据</el-button>
        <el-button type="primary" @click="Submit">确认提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isable: true,
      total: 0,
      currPage: 1,
      totalSize: 500,
      year: "",
      quarter: "",
      proNameList: [],
      prodName: [],
      reagentBrandList: [],
      checkMethodList: [],
      operationMethodList: [],
      reagentBrand: [],
      checkMethod: [],
      operationMethod: [],
      controlMaterialCodeList:[],
      controlMaterialCode: [],
      // 表格数据
      projectList: [],
      // 数据类型
      updata: "",
      updateList: [
        { id: 1, value: "已更新" },
        { id: 0, value: "未更新" },
      ],
      queryDate: "0",
      queryList: [
        { value: "默认", id: "0" },
        { value: "非默认", id: "1" },
      ],
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.getProdNameList();
    this.getReagentBrandList();
    this.getCheckMethodList();
    this.getOperationMethodList();
    this.getResultDetails();
    this.getControlMaterialCodeList()
  },
  methods: {
    /* PT允许范围填写自动同步 */
    PTone(val) {
      if (this.queryDate == "1") {
        this.projectList.map((item) => {
          item.ptValue = val;
        });
      }
    },
    PTtwo(val) {
      if (this.queryDate == "1") {
        this.projectList.map((item) => {
          item.ptValue2 = val;
        });
      }
    },
    /* 靶值填写自动同步 */
    targetAll(val) {
      if (this.queryDate == "1") {
        this.projectList.map((item) => {
          item.targetValue = val;
        });
      }
    },
    // 获取项目列表
    async getProdNameList() {
      const { data: res } = await this.$http.post(
        "quality/data/result/get/config",
        {
          year: this.year,
          quarter: this.quarter,
          title: "prodName",
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.proNameList = res.data;
      }
    },
    // 获取试剂品牌列表
    async getReagentBrandList() {
      const { data: res } = await this.$http.post(
        "quality/data/result/get/config",
        {
          year: this.year,
          quarter: this.quarter,
          title: "reagentBrand",
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.reagentBrandList = res.data;
      }
    },
    // 获取检测方法列表
    async getCheckMethodList() {
      const { data: res } = await this.$http.post(
        "quality/data/result/get/config",
        {
          year: this.year,
          quarter: this.quarter,
          title: "checkMethod",
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.checkMethodList = res.data;
      }
    },
    // 获取操作方法列表
    async getOperationMethodList() {
      const { data: res } = await this.$http.post(
        "quality/data/result/get/config",
        {
          year: this.year,
          quarter: this.quarter,
          title: "operationMethod",
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.operationMethodList = res.data;
      }
    },
    // 获取质控品编号列表
    async getControlMaterialCodeList() {
      const { data: res } = await this.$http.post(
        "sysConfig/result/controlMaterialCode",
        {
          year: this.year,
          quarter: this.quarter,
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        // console.log(res.data)
        this.controlMaterialCodeList = res.data;
      }
    },
    //获取成绩表数据
    async getResultDetails() {
      const { data: res } = await this.$http.post("quality/data/result/list", {
        year: this.year,
        quarter: this.quarter,
        totalSize: this.totalSize,
        currPage: this.currPage,
        defaultValueStatus: this.queryDate,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data.records;
        this.total = res.data.total;
        this.setrowspans();
      }
      // console.log(res);
    },
    // 查询数据
    async query() {
      if (this.queryDate == "0") {
        this.isable = true;
        this.prodName = [];
        this.checkMethod = [];
        this.reagentBrand = [];
        this.operationMethod = [];
        this.updata = "";
        this.controlMaterialCode = [];
      } else {
        this.isable = false;
      }
      const { data: res } = await this.$http.post("quality/data/result/list", {
        year: this.year,
        quarter: this.quarter,
        totalSize: this.totalSize,
        currPage: this.currPage,
        checkMethod: this.checkMethod,
        operationMethod: this.operationMethod,
        prodName: this.prodName,
        reagentBrand: this.reagentBrand,
        status: this.updata,
        defaultValueStatus: this.queryDate,
        controlMaterialCode: this.controlMaterialCode
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data.records;
        this.total = res.data.total;
        this.setrowspans();
      }
      // console.log(res);
    },
    //更新数据
    async update() {
      const { data: res } = await this.$http.post(
        "quality/data/result/update",
        {
          year: this.year,
          prodName: this.prodName,
          quarter: this.quarter,
          status: "0",
          sysReportResultUpdateListReq: this.projectList,
          defaultValueStatus: this.queryDate,
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("更新数据成功");
        this.getResultDetails();
      }
      // console.log(res);
    },
    // 提交数据
    async Submit() {
      const { data: res } = await this.$http.post(
        "quality/data/result/update",
        {
          year: this.year,
          prodName: this.prodName,
          quarter: this.quarter,
          status: "1",
          sysReportResultUpdateListReq: this.projectList,
          defaultValueStatus: this.queryDate,
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("提交数据成功");
        this.getResultDetails();
      }
      // console.log(res);
    },
    //每一页显示条数
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      // this.getResultDetails();
      this.query();
    },
    //当前页改变
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      // this.getResultDetails();
      this.query();
    },
    // 合并表格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.rowspan,
          colspan: 1,
        };
      }
    },
    setrowspans() {
      // 先给所有的数据都加一个v.rowspan = 1
      this.projectList.forEach((v) => {
        v.rowspan = 1;
      });
      // 双层循环
      for (let i = 0; i < this.projectList.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < this.projectList.length; j++) {
          //此处可根据相同字段进行合并，此处是根据的id
          if (
            this.projectList[i].prodNameResult ===
            this.projectList[j].prodNameResult
          ) {
            this.projectList[i].rowspan++;
            this.projectList[j].rowspan--;
          }
        }
        // 这里跳过已经重复的数据
        i = i + this.projectList[i].rowspan - 1;
      }
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.tbl {
  // width: 921px;
  .line {
    font-size: 30px;
  }
  margin: 0 auto;
  .el-input {
    width: 40%;
  }
  .el-input__inner {
    border: none;
  }
}
.page {
  min-width: 400px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  text-align: center;
  /deep/.el-pagination {
    min-width: 400px !important;
  }
}
.btn {
  margin-right: 60px;
  text-align: right;
}
.title {
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  div {
    // margin-right: 65px;
    margin-right: 25px;
    .el-checkbox-group {
      display: inline-block;
      margin-left: 20px;
    }
  }
}
</style>