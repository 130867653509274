<template>
  <div id="app">
    <el-container class="home-container">
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="200px">
          <div class="logo">
            <img src="../assets/logo.png">
          </div>
          <!-- 侧边栏菜单 -->
          <el-menu
            background-color="#333744"
            text-color="#fff"
            active-text-color="#409eff"
            router
            :default-active="activePath"
          >
            <!-- 一级菜单 -->
            <el-submenu
              :index="item.id + ''"
              v-for="item in menuList"
              :key="item.id"
            >
              <!-- 一级菜单模板 -->
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-menu"></i>
                <!-- 文本 -->
                <span>{{ item.authName }}</span>
              </template>
              <!-- 二级子菜单 -->
              <el-menu-item
                :index="'/' + subItem.path"
                v-for="subItem in item.children"
                :key="subItem.id"
                @click="gorouter('/' + subItem.path,item.authName,subItem.authName)"
              >
                <!-- 二级菜单模板 -->
                <template slot="title">
                  <!-- 文本 -->
                  <span>{{ subItem.authName }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
          <el-button class="logout" type="info" @click="logout">注销</el-button>
        </el-aside>
        <!-- 主体结构 -->
        <el-main>
          <div class="top">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
              <el-breadcrumb-item class="active" :to="{ path: '' }">{{
                itemName
              }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="right">
              <!-- <i class="el-icon-question"></i>
              <span>帮助</span> -->
              <!-- <el-button type="info" @click="logout">注销</el-button> -->
              <span class="yuan"></span>
              <span>{{ username }}</span>
            </div>
          </div>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  created() {
    this.username = window.sessionStorage.getItem('name')
    // 在created阶段请求左侧菜单数据
    // this.getMenuList()
    this.activePath = window.sessionStorage.getItem("activePath");
    this.name = window.sessionStorage.getItem("firstName");
    this.itemName = window.sessionStorage.getItem("subName");
  },
  data() {
    return {
      username: "",
      // 左侧菜单数据
      menuList: [
        {
          id: 1,
          authName: "室间质评",
          children: [
            { authName: "数据上报列表", id: 1.1, path: "report" },
            { authName: "数据导出列表", id: 1.2, path: "export" },
            { authName: "质评成绩列表", id: 1.3, path: "result" },
          ],
        },
        {
          authName: "质评活动公告",
          id: 2,
          children: [
            { authName: "创建公告", id: 2.1, path: "notice" },
            { authName: "新闻", id: 2.2, path: "news" },
          ],
        },
        {
          authName: "系统管理",
          id: 3,
          children: [
            { authName: "总成绩表", id:3.4, path: "totalscore"},
            { authName: "每次质评用户信息", id: 3.1, path: "qualityuser" },
            { authName: "所有用户信息", id: 3.2, path: "alluser" },
            { authName: "黑名单", id: 3.3, path: "blacklist" },
          ],
        },
        {
          authName: "帮助中心",
          id: 4,
          children: [
            { authName: "操作说明", id: 4.1, path: "explain" },
            { authName: "联系管理员", id: 4.2, path: "contactadmin" },
            { authName: "短信编辑", id: 4.3, path: "choicenessage" },
          ],
        },
         {
          authName: "配置管理",
          id: 5,
          children: [
            { authName: "创建项目", id: 5.1, path: "configmanagement" },
            { authName: "项目列表", id: 3.2, path: "creatproList" },
            { authName: "新增配置", id: 3.3, path: "config" },
            { authName: "图片管理", id: 3.4, path: "picturelist" },
          ],
        },
      ],
      activePath: "",
      name: "",
      itemName: "",
    };
  },

  methods: {
    gorouter(path,firstName,subName) {
      // var firstName = this.menuList.filter((item) => {
      //   return item.id == id;
      // });
      // this.name = firstName[0].authName;
      // var subName = firstName[0].children.filter((item) => {
      //   return item.id == itemId;
      // });
      // this.itemName = subName[0].authName;

      window.sessionStorage.setItem("activePath", path);
      window.sessionStorage.setItem("firstName", firstName);
      window.sessionStorage.setItem("subName", subName);
      this.activePath = path;
      this.name=firstName
      this.itemName=subName
    },
   async logout(){      
      await this.$http.post('sys/admin/logout')
      window.sessionStorage.clear()
      window.localStorage.clear()
      this.$router.push('login')
    }
  },
};
</script>

<style lang="less" scoped>
.logout{
  width: 150px;
  margin: 20px 0 0 20px;
}
#app {
  height: 100%;
}
.home-container {
  height: 100%;
}

.el-aside {
  background-color: #333744;
  height: 100%;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
  height: 100%;
}
/deep/.top{
  background: #409EFF;
  color: #fff;
  .el-breadcrumb__separator{
    color: #fff;
  }
  .el-breadcrumb__inner{
    color: #fff !important;
    font-size: 20px;
  }
}
.logo {
  width: 145px;
  height: 32px;
  color: #fff;
  background: #ccc;
  margin: 20px 25px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding-top: 5px;
}
</style>
