<template>
  <div>
    <el-card>
       <div class="carditem">
        <div class="details" v-for="item in ContactAdminList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="godetails(item.id)">{{item.title }}</span>
          <el-button type="danger" @click="deleteContact(item.id)">删除</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button class="btn" type="primary" @click="goCreatContact">创建联系管理员</el-button>
      <!-- <div
        class="notice_inner"
        v-for="item in ContactAdminList"
        :key="item.index"
      >
        <div class="title" @click="godetails(item.id)">
          <i class="el-icon-caret-right"></i>
          <span>{{ item.title }}</span>
        </div>
        <el-button type="danger" @click="deleteContact(item.id)">删除</el-button>
      </div> -->

      <!-- <div class="footer">
        <el-input
          class="ex_title"
          type="text"
          placeholder="请输入联系管理员标题"
          v-model="title"
        ></el-input>
        <el-input resize="none" type="textarea" :rows="15" v-model="textarea">
        </el-input>
        <div class="upload">
          <el-button type="primary" @click="addContactAdmin">创建</el-button>
        </div>
      </div> -->
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      ContactAdminList: [],
      title: "",
      textarea: "",
    };
  },
  created() {
    this.getContactAdminList();
  },
  methods: {
    // 获取联系管理员列表
    async getContactAdminList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        titleStatus: 3,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.ContactAdminList = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },
     // 删除管理员
     async deleteContact(id){
      const confirm = await this.$confirm("确定删除该条联系管理员数据吗？","提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err =>err);
      if(confirm == 'confirm'){
        const {data :res} =await this.$http.post('sys/news/info/delete',{
         id:id
        })
        if(res.code !=200){
          this.$message.error(res.message)
        }else{
          this.$message.success('已成功删除该条联系管理员数据')
          this.getContactAdminList()
        }
      }
    },
    // // 创建管理员数据
    // async addContactAdmin() {
    //   const { data: res } = await this.$http.post("sys/news/info/addNews", {
    //     titleStatus: 3,
    //     content: this.textarea,
    //     title: this.title,
    //   });
    //   if (res.code != 200) {
    //     this.$message.error(res.message);
    //   } else {
    //     this.$message.success("联系管理员创建成功");
    //     this.getContactAdminList();
    //     this.title=''
    //     this.textarea=''
    //     // this.$router.push("/news");
    //   }
    // },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getContactAdminList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getContactAdminList();
    },
    goCreatContact(){
      this.$router.push({
        path:'/createcontact'
      })
    },
    godetails(id) {
      this.$router.push({
        path: "/createcontact",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-textarea {
  width: 90%;
  margin: 30px 0 0 30px;
}
.ex_title {
  width: 50%;
  margin-left: 300px;
}
.notice_inner {
  margin-bottom: 20px;
  padding: 0 100px;
  display: flex;
  // justify-content: space-between;
  line-height: 25px;
  // font-size: 14px;
  // margin-bottom: 20px;
  .title {
    margin-right: 150px;
    cursor: pointer;
  }
  i {
    display: inline-block;
    margin-right: 5px;
    color: #ccc;
    line-height: 27px;
  }
  .el-button {
    padding: 0;
    width: 100px;
    height: 35px;
    // margin-left: 100px;
  }
}
.upload {
  text-align: right;
  .el-button {
    margin: 30px 150px 0 0;
  }
}
.footer{
  margin-top: 150px;
}
</style>