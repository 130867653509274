
<template>
  <div class="login_container">
    <!-- 登录盒子  -->
    <div class="login_box">
      <!-- 登录表单 -->
      <el-form
        :model="loginForm"
        ref="LoginFormRef"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <span>username：</span>
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <span>password：</span>
          <el-input
            type="password"
            v-model="loginForm.password"
            show-password
            prefix-icon="el-icon-lock"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login"
            >Log in</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //数据绑定
      loginForm: {
        username: "",
        password: "",
      },
      //表单验证规则
      loginFormRules: {
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "登录名长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created(){
    window.localStorage.clear()
  },
  methods: {
    //登录
    login() {
      // window.sessionStorage.clear()
      // window.localStorage.clear()
      this.$refs.LoginFormRef.validate(async (val) => {
        // console.log(val);
        if (!val) return;
        const { data: res } = await this.$http.post(
          "sys/admin/user/login",
          this.loginForm
        );
        // console.log(res);
        if (res.code !== 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("登陆成功");
          window.sessionStorage.setItem('name',this.loginForm.username)
          window.localStorage.setItem('token',res.data.accessToken)
          this.$router.push({
            path: "/home",
          });
        }
      });
      // //保存token
      // window.sessionStorage.setItem("token", res.data.token);
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.login_box {
  width: 450px;
  height: 300px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 80%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: center;
}
</style>