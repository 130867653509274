<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in resultList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="gotadeils(item.year, item.quarter)">{{
            item.year + "年" + item.quarter + item.title
          }}</span>
          <el-button
            class="btn1"
            type="primary"
            @click="createUserResult(item.year, item.quarter)"
            >生成用户成绩表</el-button
          >
          <el-button
            type="primary"
            @click="createResult(item.year, item.quarter)"
            >生成成绩表</el-button
          >
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      resultList: [
        // { title: "2021年下半年质评成绩表" }
      ],
    };
  },
  created() {
    this.getResultList();
  },
  methods: {
    // 获取成绩表列表
    async getResultList() {
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: '质评成绩表'
        titleCode: "3",
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.resultList = res.data.records;
        this.total = res.data.total;
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getResultList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getResultList();
    },
    // 查看成绩表详情
    gotadeils(year, quarter) {
      this.$router.push({
        path: "/resultlist",
        query: {
          year: year,
          quarter: quarter,
        },
      });
    },
    // 生成成绩表
    createResult(year, quarter) {
      this.$confirm("确定生成成绩表吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
          const { data: res } = await this.$http.post(
            "quality/data/result/create",
            {
              year: year,
              quarter: quarter,
            }
          );
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("成绩表已生成");
            // this.$router.push('/resultlist')
          }
        }).catch((err) => err);
    },
    // 生成用户成绩表
    createUserResult(year, quarter) {
      this.$confirm("确定生成用户成绩表吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
          const { data: res } = await this.$http.post(
            "result/data/user/result/createBatch",
            {
              year: year,
              quarter: quarter,
            }
          );
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("用户成绩表已生成");
            // this.$router.push('/resultlist')
          }
        }).catch((err) => err);
    },
  },
};
</script>

<style lang="less" scoped>
</style>