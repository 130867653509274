<template>
  <div>
    <el-card>
      <el-input v-model="year1" placeholder="请输入要查找的年份"></el-input>
      <el-input
        v-model="quarter1"
        placeholder="请输入要查找的年份季度"
      ></el-input>
      <el-button type="primary" @click="query">查询</el-button>
      <el-button type="danger" @click="deletePro">删除</el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="year" label="年份" min-width></el-table-column>
        <el-table-column
          prop="quarter"
          label="年份季度"
          min-width
        ></el-table-column>
        <el-table-column
          prop="prodName"
          label="项目名称"
          width="200"
        ></el-table-column>
        <el-table-column label="" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.reagentBrandTitle }}</span>
            <el-button
              type="primary"
              @click="
                addReagentBrand(
                  scope.row.year,
                  scope.row.quarter,
                  scope.row.id,
                  scope.row.prodName
                )
              "
              >添加</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.checkMethodTitle }}</span>
            <el-button
              type="primary"
              @click="
                addCheckMethod(
                  scope.row.year,
                  scope.row.quarter,
                  scope.row.id,
                  scope.row.prodName
                )
              "
              >添加</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.operationMethodTitle }}</span>
            <el-button
              type="primary"
              @click="
                addOperationMethod(
                  scope.row.year,
                  scope.row.quarter,
                  scope.row.id,
                  scope.row.prodName
                )
              "
              v-if="scope.row.operationMethodTitle.slice(0, 3) != '参考值'"
              >添加</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="modelTitle"
          label=""
          width="300"
        ></el-table-column>
        <el-table-column
          prop="checkResult1Title"
          label=""
          width="400"
        ></el-table-column>
        <el-table-column
          prop="checkResult2Title"
          label=""
          width="400"
        ></el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="success" @click="update(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="是否生效">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="1"
              inactive-value="0"
              disabled
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 添加对话框 -->
      <el-dialog
        title="内容创建"
        :visible.sync="proVisible"
        width="60%"
        class="dialog"
        @close="addDiaClose"
      >
        <!-- 第一次的内容 -->
        <!-- <div v-if="CreateInput.length == 0">
          <el-checkbox-group v-model="checkOneList" @change="checkChange">
            <el-checkbox
              v-for="(item, index) in checkOneProList"
              :key="index"
              :label="item.paramValue"
              >{{ item.paramValue }}</el-checkbox
            >
          </el-checkbox-group>
        </div> -->
        <!-- 第一次的内容 -->
        <!-- <div v-if="CreateInput.length == 0">
          <el-input
            v-for="(item, index) in OneCreateInput"
            :key="index"
            v-model="item.paramValue"
          ></el-input>
        </div> -->
        <h2>{{prodName}}</h2>
        <P v-if="CreateInput.length != 0">请选择内容删除：</P>
        <div>
          <el-checkbox-group v-model="checkList" @change="checkChange">
            <el-checkbox
              v-for="(item, index) in checkProList"
              :key="index"
              :label="item.id"
              >{{ item.paramValue }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <el-button class="detele" v-if="CreateInput.length != 0" type="danger" @click="deleteReport()">删除</el-button>
        <P v-if="CreateInput.length != 0">请修改已创建的内容：</P>
        <div>
          <el-input
            v-for="(item, index) in CreateInput"
            :key="index"
            v-model="item.paramValue"
          ></el-input>
        </div>
        <el-button class="update"
            v-if="CreateInput.length != 0"
            type="success"
            @click="UpdateReport()"
            >修改</el-button
          >
        <P>请添加要创建的项目：</P>
        <div>
          <el-input
            v-for="(item, index) in InputList"
            :key="index"
            v-model="inputModel[index]"
          ></el-input>
        </div>
        <div>
        <el-button type="primary" @click="add">添加项目</el-button>
        <el-button type="primary" @click="sub">删减项目</el-button>
        </div>
        <el-button class="create" type="primary" @click="goreport()">创建</el-button>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="proVisible = false">取 消</el-button>
          <el-button type="primary" @click="goreport()">创建</el-button>
          <el-button
            v-if="CreateInput.length != 0"
            type="success"
            @click="UpdateReport()"
            >修改</el-button
          >
          <el-button class="detele" v-if="CreateInput.length != 0" type="danger" @click="deleteReport()">删除</el-button>
        </span> -->
      </el-dialog>
      <!-- 修改对话框 -->
      <el-dialog
        title="内容修改"
        :visible.sync="updateProVisible"
        width="35%"
        class="Updatedialog"
      >
        <el-form :model="UpdateForm">
          <el-form-item>
            <el-input v-model="UpdateForm.prodName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.reagentBrandTitle"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.checkMethodTitle"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.operationMethodTitle"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.modelTitle"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.checkResult1Title"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="UpdateForm.checkResult2Title"></el-input>
          </el-form-item>
          <el-form-item label="是否生效">
            <el-switch
              v-model="UpdateForm.status"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateProVisible = false">取 消</el-button>
          <el-button type="primary" @click="goUpdate()">确认修改</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 查询年月
      year1: "",
      quarter1: "",
      // 首次创建内容对话框的复选框选中的值
      checkOneList: [],
      // 首次复选框内容
      checkOneProList: [],
      OneCreateInput: [],
      // 创建内容对话框的复选框选中的值
      checkList: [],
      // 复选框内容
      checkProList: [],
      // 修改对话框
      updateProVisible: false,
      UpdateForm: {},
      // 创建对话框
      proVisible: false,
      id: "",
      firstKey: "",
      year: "",
      quarter: "",
      prodName: "",
      total: 0,
      currPage: 1,
      totalSize: 10,
      // 所有用户列表数据
      tableData: [],
      InputList: 1,
      inputModel: [],
      CreateInput: [],
      // 选中的项目id
      checkUserid: [],
      checkPro: "",
    };
  },

  created() {
    this.getUserList();
  },
  methods: {
    add() {
      this.InputList = this.InputList + 1;
    },
    sub() {
      if (this.InputList <= 1) {
        this.$message.error("至少添加一个项目");
      } else {
        this.InputList = this.InputList - 1;
      }
    },
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post("product/info/info/page", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        status: "1",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      console.log(res);
    },
    // 查询用户
    async query() {
      const { data: res } = await this.$http.post("product/info/info/page", {
        year: this.year1,
        quarter: this.quarter1,
        status: "1",
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    update(val) {
      this.updateProVisible = true;
      this.UpdateForm = val;
    },
    addDiaClose() {
      this.checkList = [];
      this.InputList = 1;
    },
    // 对话框点击确定修改
    async goUpdate() {
      const { data: res } = await this.$http.post(
        "product/info/info/update",
        this.UpdateForm
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("修改成功");
        this.updateProVisible = false;
      }
      // console.log(res)
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getUserList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getUserList();
    },
    // 添加试剂品牌
    async addReagentBrand(year, quarter, id, prodName) {
      this.year = year;
      this.quarter = quarter;
      this.id = id;
      this.firstKey = "reagentBrand";
      this.proVisible = true;
      this.prodName = prodName;
      const { data: res } = await this.$http.post("sysConfig/select/getValue", {
        year: year,
        quarter: quarter,
        prodId: id,
        firstKey: "reagentBrand",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        // console.log(res)
        this.CreateInput = res.data;
        this.checkProList = JSON.parse(JSON.stringify(res.data));
      }
    },
    // 添加检测方法
    async addCheckMethod(year, quarter, id, prodName) {
      this.year = year;
      this.quarter = quarter;
      this.id = id;
      this.firstKey = "checkMethod";
      this.proVisible = true;
      this.prodName = prodName;
      const { data: res } = await this.$http.post("sysConfig/select/getValue", {
        year: year,
        quarter: quarter,
        prodId: id,
        firstKey: "checkMethod",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.CreateInput = res.data;
        this.checkProList = JSON.parse(JSON.stringify(res.data));
      }
    },
    // 添加操作方法
    async addOperationMethod(year, quarter, id, prodName) {
      this.year = year;
      this.quarter = quarter;
      this.id = id;
      this.firstKey = "operationMethod";
      this.proVisible = true;
      this.prodName = prodName;
      const { data: res } = await this.$http.post("sysConfig/select/getValue", {
        year: year,
        quarter: quarter,
        prodId: id,
        firstKey: "operationMethod",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.CreateInput = res.data;
        this.checkProList = JSON.parse(JSON.stringify(res.data));
      }
      // 判断是否为第一次
      // if (this.CreateInput.length == 0) {
      //   const { data: res } = await this.$http.post(
      //     "sysConfig/config/lastTimes",
      //     {
      //       prodId: id,
      //       firstKey: "operationMethod",
      //       prodName: prodName,
      //     }
      //   );
      //   if (res.code != 200) {
      //     this.$message.error(res.message);
      //   } else {
      //     // console.log(res)
      //     this.OneCreateInput = res.data;
      //     this.checkOneProList = JSON.parse(JSON.stringify(res.data));
      //   }
      // }
    },
    // 对话点击确定创建项目
    async goreport() {
      // 首次创建
      // if (this.CreateInput.length == 0 && this.OneCreateInput.length != 0) {
      //   var paramValue = this.OneCreateInput.map((item) => {
      //     return item.paramValue;
      //   });
      //   const { data: res } = await this.$http.post(
      //     "sysConfig/product/insert",
      //     {
      //       year: this.year,
      //       quarter: this.quarter,
      //       paramValue: paramValue,
      //       firstKey: this.firstKey,
      //       prodId: this.id,
      //       prodName: this.prodName,
      //     }
      //   );
      //   if (res.code != 200) {
      //     this.$message.error(res.message);
      //   } else {
      //     this.$message.success("创建成功");
      //     this.proVisible = false;
      //     // this.inputModel = [];
      //   }
      // } else {
        if (!this.inputModel[0]) {
          this.$message.error("添加项目为空");
        } else {
          const { data: res } = await this.$http.post(
            "sysConfig/product/insert",
            {
              year: this.year,
              quarter: this.quarter,
              paramValue: this.inputModel,
              firstKey: this.firstKey,
              prodId: this.id,
              prodName: this.prodName,
            }
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("创建成功");
            this.proVisible = false;
            this.inputModel = [];
          }
        }
      // }
    },
    // 删除创建对话框里面内容
    async deleteReport() {
      // 首次的删除
      // if (this.CreateInput.length == 0) {
      //   const { data: res } = await this.$http.post(
      //     "sysConfig/delete",
      //     this.checkOneList
      //   );
      //   if (res.code != 200) {
      //     this.$message.error(res.message);
      //   } else {
      //     this.$message.success("删除成功");
      //     this.proVisible = false;
      //   }
      // } else {
        const { data: res } = await this.$http.post(
          "sysConfig/delete",
          this.checkList
        );
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("删除成功");
          this.proVisible = false;
        }
      // }
    },
    //  修改对话框里面的内容
    async UpdateReport() {
      const { data: res } = await this.$http.post(
        "sysConfig/config/updateBatch",
        this.CreateInput
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("修改成功");
        this.proVisible = false;
      }
    },
    // 删除数据
    async deletePro() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      }
      //  else if (this.checkUserid.length > 1) {
      //   this.$message.error("只能选中一个删除");
      // }
      else {
        const confirm = await this.$confirm("确定删除该项目吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          const { data: res } = await this.$http.post(
            "product/info/info/delete",
            this.checkUserid
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("删除成功");
            this.getUserList();
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.checkUserid = val.map((item) => {
        return item.id;
      });
      // this.checkPro = val[0].id;
      // console.log(val);
      // console.log(this.checkUserid);
    },
    checkChange(e) {
      console.log(e);
      console.log(this.checkList);
    },
  },
};
</script>
<style lang="less" scoped>
.el-button {
  margin-left: 20px;
}
.el-input {
  width: 200px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.dialog {
  // position: relative;
  .el-dialog__body{
    h2{
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }
    // position: relative;
    .detele,.update,.create{
    // position: absolute;
    // top: 25px;
    float: right;
    margin-top: -60px;
  }
  }
  p{
    font-size: 18px;
    margin-bottom: 15px;
    color: red;
  }
  
  .el-button {
    margin-right: 20px;
    margin-left: 0;
  }
  /deep/.el-checkbox-group {
    margin-bottom: 30px;
    .el-checkbox__label {
      font-size: 16px !important;
    }
  }
   /deep/.el-checkbox__inner{
    width: 18px;
    height: 18px;
    border: 2px solid #000;
  }
}
/deep/.Updatedialog {
  .el-dialog__body {
    padding: 30px 20px 30px 100px;
  }
  .el-form-item {
    margin-bottom: 0px;
    .el-input {
      width: 350px;
    }
  }
}
</style>
