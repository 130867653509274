<template>
  <div>
    <el-input v-model="title" placeholder="请输入新闻标题"></el-input>
    <div class="tinymce-editor">
      <div id="tinymceToolbar"></div>
      <vue-tinymce
        ref="editor"
        class="editor overflow-scroll"
        v-model="content"
        :setting="setting"
        @keyup.native="$emit('editorChange', content)"
      />
    </div>
    <div class="btn">
      <el-button type="primary" @click="addNews" :disabled="subAble"
        >保存</el-button
      >
      <!-- <el-button type="primary" @click="update" :disabled="udAble"
        >修改</el-button
      >
      <el-button type="primary" @click="goback">返回</el-button> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "tinymceEditor",
  components: {},
  data() {
    return {
      subAble: true,
      udAble: true,
      id: "",
      title: "",
      // 编辑器内容
      content: "",
      // 编辑器设置
      setting: {
        inline: true, // 设置内敛模式
        menubar: false, // 隐藏菜单栏
        autoresize_on_init: false,
        toolbar:
          "code undo redo restoredraft |  pastetext | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | \
        　　　　styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
        　　　　table image  charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs |",
        //工具栏，根据需要写对应的工具名称，顺序及分割线等等，这里的最后的 ‘myUpload’ 是下面setup里的自定义插件
        toolbar_mode: "sliding", // 工具栏模式，这里是滑行模式（当屏幕过小时，多余的工具会隐藏，点击更多按钮会出现其他工具，这里配置出现的特效）
        plugins:
          "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools  link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs uploadimage", // 需要用到的功能插件，如链接，列表等等
        language: "zh_CN", // 语言，汉化
        branding: false, // 隐藏品牌，隐藏状态栏中显示的“ Powered by Tiny ”链接
        resize: false, // 是否可以缩放编辑器
        elementpath: false, // 在编辑器底部的状态栏中禁用元素路径。
        fixed_toolbar_container: "#tinymceToolbar", // 可以设置元素选择器指定工具栏嵌套在哪个元素里面
        custom_ui_selector: "body", // 聚焦的元素
        noneditable_noneditable_class: "mceNonEditable", // 使用此选项，您可以指定TinyMCE将使用的类名称，以确定使用noneditable插件时可编辑哪些内容区域。主要用入你想以代码的形式添加某些内容，并给这些内容设置类名，使他们不可编辑，只能整个删除
        init_instance_callback: (editor) => {
          editor.focus(); // 初始化聚焦，让内联模式的编辑器工具显示
        },
         images_upload_handler: async function (blobInfo, succFun, failFun) {
          var file = blobInfo.blob()
         let formData = new FormData();
         console.log(formData)
         formData.append("file", file); //此处与源文档不一样
         const {data:res} =await axios.post('sys/oss/uploadImage',formData);
          if(res.code!=200){
            
          }else{
            var url = res.data.url
            const {data:res1} =await axios.get('sys/oss/getFile?objectName='+url.toString());
            if(res1.code!=200){

            }else{
              succFun(res1.data)
            }
          }
          // xhr.send(formData);
        },
      },
    };
  },
  created() {
    this.id = this.$route.query.id;

    if (!this.id) {
      this.subAble = false;
    } else {
      this.getNewsContent();
      this.udAble = false;
    }
    console.log(this.id);
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async addNews() {
      const { data: res } = await this.$http.post("sys/news/info/addNews?content="+this.content, {
        titleStatus: 0,
        title:this.title
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("新闻创建成功");
        this.$router.push("/news");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  margin-top: 20px;
  width: 50%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.tinymce-editor {
  padding: 20px;
  position: relative;
  .editor {
    padding: 10px;
    border: 1px solid #ddd;
    height: 410px;
    font-size: 16px;
    line-height: 1.4;
    overflow-y: scroll;
  }
}
.btn {
  margin-left: 2%;
}
</style>