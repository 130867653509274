<template>
  <div>
    <el-card>
      <p>请填写{{variable}}</p>
      <el-input v-for="(item,index) in list" :key="index" v-model="textarea[index]" class="tex" :placeholder="'请输入'+item"></el-input>
      <el-button type="primary" @click="send">发送</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:'',
      textarea: [],
      variable:'',
      list:[]
    };
  },
  created(){
    this.id = this.$route.query.id
    this.variable = this.$route.query.variable
    this.list = this.variable.split(',')
    console.log(this.list)
  },
  methods: {
    async send() {
      const confirm = await this.$confirm("确定群发短信给用户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirm == "confirm") {
        const { data: res } = await this.$http.post("sys/sms/batch/send", {
          id:this.id,
          year: this.textarea[0],
          n: this.textarea[1],
          time: this.textarea[2],
          deadline: this.textarea[3]
        });
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("发送成功");
          // this.textarea = [];
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tex {
  width: 40%;
  margin: 30px 0 0 30px;
  display: block;
}
.el-button{
  margin: 20px 0 0 50px;
}
p{
  margin-left: 28px;
  font-size: 20px;
}
</style>