<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in reportList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="gotadeils(item.year, item.quarter, item.title)">{{
            item.year + "年" + item.quarter + item.title
          }}</span>
          <el-button
            type="danger"
            @click="deleteReport(item.year, item.quarter)"
            >删除</el-button
          >
          <el-button type="success" class="update" @click="updateReport(item)"
            >修改</el-button
          >
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button class="btn" type="primary" @click="reportVisible = true"
        >创建上报表</el-button
      >
      <el-dialog title="创建上报表" :visible.sync="reportVisible" width="30%">
        <el-form
          :model="YearForm"
          label-width="150px"
          :rules="yearRules"
          ref="YearRef"
        >
          <el-form-item label="上报表年份" prop="year">
            <el-input v-model="YearForm.year"></el-input>
          </el-form-item>
          <el-form-item label="年份季度" prop="quarter">
            <el-input
              v-model="YearForm.quarter"
              placeholder="请输入上半年或者下半年"
            ></el-input>
          </el-form-item>
          <el-form-item label="检查项目名称" prop="proName">
            <el-input v-model="YearForm.proName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="reportVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="goreport(YearForm.year, YearForm.quarter, YearForm.proName)"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updateVisible" width="30%">
        <el-form :model="updateForm" label-width="150px" ref="YearRef">
          <el-form-item label="上报表年份" prop="year">
            <el-input v-model="updateForm.year"></el-input>
          </el-form-item>
          <el-form-item label="年份季度" prop="quarter">
            <el-input
              v-model="updateForm.quarter"
              placeholder="请输入上半年或者下半年"
            ></el-input>
          </el-form-item>
          <el-form-item label="检查项目名称" prop="title">
            <el-input v-model="updateForm.title"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="goupdate()">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      checkList: [],
      YearForm: {
        year: "",
        quarter: "",
        proName: "",
      },
      updateForm: {},
      yearRules: {
        year: [
          { required: true, message: "请输入上报表年份", trigger: "blur" },
        ],
        quarter: [
          { required: true, message: "请输入年份季度", trigger: "blur" },
        ],
        proName: [
          { required: true, message: "请输入检查项目名称", trigger: "blur" },
        ],
      },
      reportVisible: false,
      updateVisible: false,
      reportList: [],
    };
  },
  created() {
    this.getReportList();
  },
  methods: {
    fun(e) {
      console.log(e);
    },
    // 获取上报列表
    async getReportList() {
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: '质评上报表'
        titleCode: "1",
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.reportList = res.data.records;
        this.total = res.data.total;
      }
      // console.log(this.reportList.quarter);
      // this.$message.error("hahfjjaf")
    },
    //删除上报表
    async deleteReport(year, quarter) {
      const confirm = await this.$confirm("确定删除该上报表吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirm == "confirm") {
        const { data: res } = await this.$http.post("quality/evaluate/delete", {
          year: year,
          quarter: quarter,
        });
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("已成功删除该上报表");
          this.getReportList();
        }
      }
    },
    updateReport(val) {
      // this.$message.error("hahfjjaf");
      this.updateForm = JSON.parse(JSON.stringify(val));
      this.updateVisible = true;
      // console.log(val)
    },
    async goupdate() {
      const { data: res } = await this.$http.post(
        "quality/evaluate/update",
        this.updateForm
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("修改成功");
        this.updateVisible = false;
        this.getReportList()
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getReportList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getReportList();
    },
    // 创建上报表
    goreport(year, quarter, proName) {
      this.$refs.YearRef.validate((val) => {
        if (!val) return;
        this.$router.push({
          path: "/creatreport",
          query: {
            year: year,
            quarter: quarter,
            proName: proName,
          },
        });
      });
    },
    // 查看上报表详情
    gotadeils(year, quarter, title) {
      this.$router.push({
        path: "/reportdetails",
        query: {
          year: year,
          quarter: quarter,
          title: title,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  margin: 5px;
  span {
    font-size: 24px;
  }
}
</style>