<template>
  <div>
    <el-card>
      <el-table :data="exporttable" style="width: 100%" id="exportable">
        <el-table-column prop="laboratoryCode" label="实验编号" width="80">
        </el-table-column>
        <el-table-column prop="unitName" label="单位名称" width="180">
        </el-table-column>
        <el-table-column prop="laboratoryName" label="实验室名称" width="150">
        </el-table-column>
        <el-table-column
          prop="qualityControlPerson"
          label="质控联系人"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="mobile" label="联系人电话" width="150">
        </el-table-column>
        <el-table-column
          prop="qualityContPerMail"
          label="联系人邮箱"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="qualityContPerAddress" label="地址" width="150">
        </el-table-column>
        <!-- <el-table-column prop="ptAll" label="PT总分" width="150">
        </el-table-column> -->
        <el-table-column
          :label="item.prodName"
          v-for="(item, index) in exporttable[0].prodInfoList"
          :key="index"
        >
          <el-table-column :label="item.prodExportTitle[0]" width="90">
            <template slot-scope="scope">
              <span>{{
                scope.row.prodInfoList[index].reagentBrand 
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="item.prodExportTitle[1]" width="90">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].checkMethod }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="item.prodExportTitle[2]" width="120">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].operationMethod }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="item.prodExportTitle[3]" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.prodInfoList[index].model  }} </span>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="item.prodName == 'MAR'" label="B1：PT" width="90">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].lastResult1  }}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column :label="item.prodExportTitle[4]" width="150">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].checkResult1  }}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="item.prodName == 'MAR'? 'B2：PT':'A1：PT'" width="90">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].lastResult2  }}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column :label="item.prodExportTitle[5]" width="150">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].checkResult2  }}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="item.prodName == 'MAR'? 'B3：PT': 'A2：PT'" width="90">
            <template slot-scope="scope">
              <span
                >{{ scope.row.prodInfoList[index].lastResult3  }}
              </span>
            </template>
          </el-table-column> -->
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button type="primary" @click="exportData">导出数据</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: "",
      quarter: "",
      total: 0,
      currPage: 0,
      totalSize: 10,
      // 导出列表数据
      exporttable: [
      ],
     
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.getExportList();
  },
  methods: {
    // 获取导出数据列表
    async getExportList() {
      const { data: res } = await this.$http.post("quality/data/user/list", {
        year: this.year,
        quarter: this.quarter,
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.exporttable = res.data.records;
        this.total=res.data.total
      }
      console.log(res);
    },
    async exportData(){
      const confirm = await this.$confirm("确定将用户列表导出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) =>err);
      if(confirm == "confirm"){
      window.open(this.$http1+'quality/data/user/download?year='+this.year+'&quarter='+this.quarter)
      }
    },
     // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getExportList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getExportList();
    },
  },
};
</script>
<style lang='less' >
#exportable {
  // text-align: center;
  margin-bottom: 20px;
  .el-table__cell {
    text-align: center !important;
  }
}
.page{
  margin-bottom: 20px;
}
</style>