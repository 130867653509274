<template>
  <div>
    <el-card>
      <div class="carditem">
        <p class="title">{{data.title}}</p>
              <p class="content">{{data.content}}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      data:{}
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getNewsDetails();
  },
  methods: {
    async getNewsDetails() {
      const { data: res } = await this.$http.post(
        "sys/news/info/newsInfoById",
        {
          id: this.id,
        }
      );
       if(res.code !=200){
          this.$message.errror(res.message)
      }else{
          this.data = res.data
      }
      console.log(res);
    },
  },
};
</script>
<style lang='less' scoped>
.carditem{
    .title{
        text-align: center;
    }
    .content{
        // text-indent: 2em;
        white-space: pre-wrap; 
        // white-space: pre-line;         
        //  word-break: break-all;
    }
}
</style>