import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue' //管理员登录
import Home from '../components/Home.vue'   //后台首页
// 室间质评
import A from '../components/evaluation/a.vue'  //导出报表
import Export from '../components/evaluation/Export.vue'  //导出报表
import Report from '../components/evaluation/Report.vue'  //上传报表
import ReportDetails from '../components/evaluation/ReportDetails.vue'  //上传报表
import Result from '../components/evaluation/Result.vue'  //质评成绩
import CreatReport from '../components/evaluation/CreatReport.vue'  //创建上报表
import ExportList from '../components/evaluation/ExportList.vue'   //
import ResultList from '../components/evaluation/ResultList.vue'
// 质评活动公告
import Notice from '../components/notice/Notice.vue'    //公告
import CreatNotice from '../components/notice/CreatNotice.vue'    //创建公告
import NoticeDetails from '../components/notice/NoticeDetails.vue'    //公告详情
import News from '../components/notice/News.vue'    //新闻
import NewsDetails from '../components/notice/NewsDetails.vue'    //新闻详情
import CreatNews from '../components/notice/CreatNews.vue'    //创建新闻
//系统管理
import QualityUser from '../components/user/QualityUser.vue'    //质评用户列表
import AllUser from '../components/user/AllUser.vue'    //所有用户信息
import Blacklist from '../components/user/Blacklist.vue'   //黑名单
import QualityList from '../components/user/QualityList.vue' //质评用户信息详情
import ConfigManagement from '../components/config/ConfigManagement.vue' //质评用户信息详情
import Config from '../components/config/Config.vue' //质评用户信息详情
import pictureList from '../components/config/pictureList.vue' //质评用户信息详情
import creatproList from '../components/config/creatproList.vue' //质评用户信息详情
import TotalScore from '../components/user/TotalScore.vue' //总成绩列表
import ScoreList from '../components/user/ScoreList.vue' //总成绩列表详情
// 帮助中心
import Explain from '../components/help/Explain.vue'    //操作说明
import CreatExplain from '../components/help/CreatExplain.vue'    //创建操作说明
import ContactAdmin from '../components/help/ContactAdmin.vue'   //联系管理员
import CreateContact from '../components/help/CreateContact.vue'   //创建联系管理员
import MessageEdit from '../components/help/MessageEdit.vue'  //短信编辑
import ChoiceMessage from '../components/help/ChoiceMessage.vue'  //短信编辑

// import a from '../components/a.vue'
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/home',
      component: Home,
      redirect: '/report',
      children: [
        { path: '/export', component: Export },
        { path: '/a', component: A },
        { path: '/report', component: Report },
        { path: '/reportdetails', component: ReportDetails },
        { path: '/result', component: Result },
        { path: '/creatreport', name: 'CreatReport', component: CreatReport },
        { path: '/exportlist', component: ExportList },
        { path: '/resultlist', component: ResultList },
        { path: '/notice', component: Notice },
        { path: '/noticedetails', component: NoticeDetails },
        { path: '/creatnotice', component: CreatNotice },
        { path: '/news', component: News },
        { path: '/newsdetails', component: NewsDetails },
        { path: '/creatnews', component: CreatNews },
        { path: '/totalscore', component: TotalScore },
        { path: '/scorelist', component: ScoreList },
        { path: '/qualityuser', component: QualityUser },
        { path: '/alluser', component: AllUser },
        { path: '/blacklist', component: Blacklist },
        { path: '/qualitylist', component: QualityList },
        { path: '/configmanagement', component: ConfigManagement },
        { path: '/config', component: Config },
        { path: '/picturelist', component: pictureList },
        { path: '/creatprolist', component: creatproList },
        { path: '/explain', component: Explain },
        { path: '/createxplain', component: CreatExplain },
        { path: '/contactadmin', component: ContactAdmin },
        { path: '/createcontact', component: CreateContact },
        { path: '/messageedit', component: MessageEdit },
        { path: '/choicenessage', component: ChoiceMessage },
      ]
    },
    
  ]
})

router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转

  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.localStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})
export default router
