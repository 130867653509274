<template>
  <div>
    <el-card>
      <el-form size="small" :model="form" label-width="120px" id="test">
        <el-row class="title">
          <h2>{{ year }}年{{ quarter }}{{ proName }}</h2>
          <img src="../../assets/EQA_logo.png" />
          <div class="line"></div>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="单位名称">
              <el-input
                type="text"
                v-model="form.companyname"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="实验室名称">
              <el-input
                type="text"
                v-model="form.laboratoryname"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="实验室编号">
              <el-input
                type="text"
                v-model="form.laboratoryid"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="回报截止日期">
              <el-input type="text" v-model="form.data" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          :label="item.prodName"
          class="protitle"
          v-for="(item, index) in projectList"
          :key="index"
          label-width="380px"
        >
          <el-table
            :data="item.productInfoList"
            border
            size="mini"
            style="width: 100%"
          >
            <el-table-column :label="item.reagentBrandTitle" width="160">
              <template slot-scope="scope">
                <el-checkbox-group
                  v-model="scope.row.reagentBrand"
                  @change="checkReagentBrand(scope.row)"
                  disabled
                >
                  <el-checkbox
                    v-for="(item, index) in scope.row.reagentBrandList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
              <!-- <el-input type="text" v-model="form.tableData.name"></el-input> -->
            </el-table-column>
            <el-table-column :label="item.checkMethodTitle" width="200">
              <template slot-scope="scope">
                <el-checkbox-group
                  v-model="scope.row.checkMethod"
                  @change="checkCheckMethod(scope.row)"
                  disabled
                >
                  <el-checkbox
                    v-for="(item, index) in scope.row.checkMethodList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column :label="item.operationMethodTitle" width="160">
              <template slot-scope="scope">
                <el-checkbox-group v-model="scope.row.operationMethod" disabled>
                  <el-checkbox
                    v-for="(item, index) in scope.row.operationMethodList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
                <!-- <el-input type="text" v-model="scope.row.operationMethod" disabled></el-input> -->
              </template>
            </el-table-column>
            <el-table-column :label="item.modelTitle" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.model"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="item.checkResult1Title" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult1"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="item.checkResult2Title" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult2"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  active-value="1"
                  inactive-value="0"
                  disabled
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row class="tips">
          <p>
            （数据填报举例：若MAR检查结果为53.2%，则直接填报“53.2”，不要填报“53.2%”或“0.532”）
          </p>
        </el-row>
        <el-row :gutter="24" class="bottom_row">
          <el-col :span="12">
            <el-form-item label="实验室负责人">
              <el-input type="text" v-model="form.person" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人手机">
              <el-input type="text" v-model="form.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: "",
      quarter: "",
      proName: "",
      // 上面部分表单
      form: {
        companyname: "",
        laboratoryname: "",
        laboratoryid: "",
        data: "",
        person: "",
        phone: "",
      },
      // 检查项目列表
      projectList: [
        // {
        //   prodName: "精浆果糖测定",
        //   productInfoList: [
        //     {
        //       reagentBrand: [],
        //       checkMethod: [],
        //       operationMethod: [],
        //       model: "",
        //       checkResult1: "",
        //       twoRcheckResult2: "",
        //     },
        //   ],
        // }
      ],
    };
  },

  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.proName = this.$route.query.title;
    this.getCreatResport();
    this.getReturnData();
  },

  methods: {
    // 获取上报表内容
    async getCreatResport() {
      const { data: res } = await this.$http.post("quality/evaluate/select", {
        year: this.year,
        quarter: this.quarter,
      });
      // console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data;
      }
      // console.log(this.projectList)
    },
    // 获取截止日期
    async getReturnData() {
      const { data: res } = await this.$http.post("sysConfig/select/getValue", {
        year: this.year,
        quarter: this.quarter,
        firstKey: "return_date",
      });
      // console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.form.data = res.data[0].paramValue;
      }
      // console.log(this.projectList)
    },
  },
};
</script>
<style lang='less'>
#test {
  border: 1px solid #000;
  border-collapse: collapse;
  width: 96%;
  margin: 0 auto;
  .tips {
    text-align: center;
  }
  .title {
    text-align: center;
    margin: 20px 0;
    height: 70px;
    img {
      width: 165px;
      height: 50px;
      z-index: 99;
      background: #fff;
    }
    .line {
      width: 100%;
      border-bottom: 3px dashed #ccc;
      margin-top: -34px;
      z-index: -1;
    }
  }
  .protitle .el-form-item__label {
    line-height: 115px;
    font-size: 16px;
    font-weight: bold;
  }
  .bottom_row .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item {
    border: 1px solid #000;
    border-collapse: collapse;
    .el-table {
      color: #000;
    }
    .el-input {
      border-left: 1px solid #000;
    }
    .el-input__inner {
      width: 99%;
      border: none;
    }
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #000;
    color: #000;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #000;
  }
  .el-table--border,
  .el-table--group {
    border-left: 1px solid #000;
  }
  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    padding: 0;
    margin: 0;
  }
  .el-table .cell,
  .el-table--border .el-table__cell:first-child .cell {
    padding: 0;
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  .el-form-item__label {
    text-align: center;
  }
  th {
    font-weight: 500;
  }
}
.btn {
  margin: 20px 40px 0 0;
  float: right;
}
</style>
