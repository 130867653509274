<template>
  <div>
    <el-card>
      <el-form size="small" :model="form" label-width="120px" id="test">
        <el-row class="title">
          <h2>{{ year }}年{{ quarter }}{{ proName }}</h2>
          <img src="../../assets/EQA_logo.png" />
          <div class="line"></div>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="单位名称">
              <el-input
                type="text"
                v-model="form.companyname"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="实验室名称">
              <el-input
                type="text"
                v-model="form.laboratoryname"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="实验室编号">
              <el-input
                type="text"
                v-model="form.laboratoryid"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="回报截止日期">
              <el-input type="text" v-model="form.data"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          :label="item.prodName"
          class="protitle"
          v-for="(item, index) in projectList"
          :key="index"
          label-width="380px"
        >
          <el-table
            :data="item.productInfoList"
            border
            size="mini"
            style="width: 100%"
          >
            <el-table-column :label="item.reagentBrandTitle" width="160">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.reagentAll"
                  v-if="scope.row.reagentBrandList.length != 0"
                  @change="reagentCheckAllChange(scope.row)"
                  >全选</el-checkbox
                >
                <el-checkbox-group
                  v-model="scope.row.reagentBrand"
                  @change="checkReagentBrand(scope.row)"
                >
                  <el-checkbox
                    v-for="(item, index) in scope.row.reagentBrandList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column :label="item.checkMethodTitle" width="200">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.MethodAll"
                  @change="MethodCheckAllChange(scope.row)"
                  v-if="scope.row.checkMethodList.length != 0"
                  >全选</el-checkbox
                >
                <el-checkbox-group
                  v-model="scope.row.checkMethod"
                  @change="checkCheckMethod(scope.row)"
                >
                  <el-checkbox
                    v-for="(item, index) in scope.row.checkMethodList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column :label="item.operationMethodTitle" width="160">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.operationAll"
                  @change="operationCheckAllChange(scope.row)"
                  v-if="scope.row.operationMethodList.length != 0"
                  >全选</el-checkbox
                >
                <el-checkbox-group
                  v-model="scope.row.operationMethod"
                  @change="checkOperationMethod(scope.row)"
                >
                  <el-checkbox
                    v-for="(item, index) in scope.row.operationMethodList"
                    :label="item.paramValue"
                    :key="index"
                    >{{ item.paramValue }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column :label="item.modelTitle" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.model"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="item.checkResult1Title" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult1"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="item.checkResult2Title" width="170">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult2"
                  disabled
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  active-value="1"
                  inactive-value="0"
                  @change="userStatusChange(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row class="tips">
          <p>
            （数据填报举例：若MAR检查结果为53.2%，则直接填报“53.2”，不要填报“53.2%”或“0.532”）
          </p>
        </el-row>
        <el-row :gutter="24" class="bottom_row">
          <el-col :span="12">
            <el-form-item label="实验室负责人">
              <el-input type="text" v-model="form.person" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人手机">
              <el-input type="text" v-model="form.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn">
        <!-- <el-button type="primary">添加项目</el-button> -->
        <el-button type="primary" @click="addReport()">确认创建</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: "",
      quarter: "",
      proName: "",
      // 上面部分表单
      form: {
        companyname: "",
        laboratoryname: "",
        laboratoryid: "",
        data: "",
        person: "",
        phone: "",
      },
      // 检查项目列表
      projectList: [
        // {
        //   prodName: "精浆果糖测定",
        //   productInfoList: [
        //     {
        //       reagentBrand: [],
        //       checkMethod: [],
        //       operationMethod: [],
        //       model: "",
        //       checkResult1: "",
        //       twocheckResult2: "",
        //     },
        //   ],
        // }
      ],
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.proName = this.$route.query.proName;
    this.getCreatResport();
  },
  methods: {
    // 获取上报表内容
    async getCreatResport() {
      const { data: res } = await this.$http.post("quality/evaluate/select", {
        year: this.year,
        quarter: this.quarter,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        res.data.map((item) => {
          item.productInfoList[0].reagentAll = false;
          item.productInfoList[0].MethodAll = false;
          item.productInfoList[0].operationAll = false;
        });
        this.projectList = res.data;
        // console.log(res.data)
      }
    },
    // 判断试剂品牌是否全选
    checkReagentBrand(e) {
      if (e.reagentBrand.length == e.reagentBrandList.length) {
        e.reagentAll = true;
      } else {
        e.reagentAll = false;
      }
    },
    // 判断操作方法是否全选
    checkOperationMethod(e) {
      if (e.operationMethod.length == e.operationMethodList.length) {
        e.operationAll = true;
      } else {
        e.operationAll = false;
      }
    },
    // 判断检测方法是否全选
    checkCheckMethod(e) {
      if (e.checkMethod.length == e.checkMethodList.length) {
        e.MethodAll = true;
      } else {
        e.MethodAll = false;
      }
    },
    userStatusChange(e) {},
    // 试剂品牌全选
    reagentCheckAllChange(val) {
      const reagentList = val.reagentBrandList.map((item) => {
        return item.paramValue;
      });
      val.reagentBrand = val.reagentAll ? reagentList : [];
    },
    // 检测方法全选
    MethodCheckAllChange(val) {
      const MethodList = val.checkMethodList.map((item) => {
        return item.paramValue;
      });
      val.checkMethod = val.MethodAll ? MethodList : [];
    },
    // 操作方法全选
    operationCheckAllChange(val) {
      const operationList = val.operationMethodList.map((item) => {
        return item.paramValue;
      });
      val.operationMethod = val.operationAll ? operationList : [];
    },
    // 创建上报表
    async addReport() {
      if (!this.form.data) {
        this.$message.warning("请输入回报截止日期");
      } else {
        const { data: res } = await this.$http.post(
          "quality/evaluate/addReport",
          {
            list: this.projectList,
            title: this.proName,
            year: this.year,
            quarter: this.quarter,
            returnDate: this.form.data,
          }
        );
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("创建成功上报表");
          this.$router.push("/report");
        }
      }
    },
  },
};
</script>
<style lang='less'>
#test {
  border: 1px solid #000;
  border-collapse: collapse;
  width: 96%;
  margin: 0 auto;
  .tips {
    text-align: center;
  }
  .title {
    text-align: center;
    margin: 20px 0;
  }
  .protitle .el-form-item__label {
    line-height: 115px;
    font-size: 16px;
    font-weight: bold;
  }
  .bottom_row .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item {
    border: 1px solid #000;
    border-collapse: collapse;
    .el-table {
      color: #000;
    }
    .el-input {
      border-left: 1px solid #000;
    }
    .el-input__inner {
      width: 99%;
      border: none;
    }
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #000;
    color: #000;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #000;
  }
  .el-table--border,
  .el-table--group {
    border-left: 1px solid #000;
  }
  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    padding: 0;
    margin: 0;
  }
  .el-table .cell,
  .el-table--border .el-table__cell:first-child .cell {
    padding: 0;
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  .el-form-item__label {
    text-align: center;
  }
  th {
    font-weight: 500;
  }
}
.btn {
  margin: 20px 40px 0 0;
  float: right;
}
</style>
