<template>
  <div>
    <el-card
      v-loading="loading"
      :element-loading-text="
        '正在上传中，请勿点击其他操作，上传进度：' + percent + '%'
      "
    >
      <el-input v-model="title" placeholder="请输入公告标题"></el-input>
      <div class="tinymce-editor">
        <div id="tinymceToolbar"></div>
        <vue-tinymce
          ref="editor"
          class="editor overflow-scroll"
          v-model="content"
          :setting="setting"
          @keyup.native="$emit('editorChange', content)"
        />
      </div>
      <el-button @click="checkReport">添加上报表</el-button>

      <div :class="isSelect ? 'select' : 'active'">
        <span v-for="(item, index) in projectList" :key="index">{{
          item.prodName
        }}</span>
        <el-button type="primary" @click="sub">确认创建</el-button>
      </div>

      <el-dialog title="请选择上报表" :visible.sync="ReportVisible" width="30%">
        <el-radio-group v-model="report" @change="checkReport()">
          <el-radio
            :label="item.year + '年' + item.quarter + item.title"
            v-for="(item, index) in reportList"
            :key="index"
            >{{ item.year + "年" + item.quarter + item.title }}</el-radio
          >
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="ReportVisible = false">取 消</el-button>
          <el-button type="primary" @click="add">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
import { Message } from "element-ui";
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      subAble: true,
      udAble: true,
      id: "",
      year: "",
      quarter: "",
      currPage: 1,
      // titleStatus: "1",
      totalSize: 10,
      reportList: [],
      // 对话框单选框选中内容
      report: "",
      // 对话框显示与隐藏
      ReportVisible: false,
      // 公告标题
      title: "",
      // 公告内容
      content: "",
      // 项目多选框选择内容
      checkedPro: [],
      isSelect: false,
      projectList: [],
      //视频上传进度数据
      percent: 0,
      //视频上传进度条
      loading: false,
      // 富文本配置
      setting: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.TinymceSetting();
  },
  methods: {
    async checkReport() {
      this.ReportVisible = true;
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: "质评上报表",
        titleCode: "1",
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.reportList = res.data.records;
      }
      console.log(res);
      // console.log(this.report)
      this.year = this.report.substr(0, 4);
      this.quarter = this.report.substr(5, 3);
    },
    TinymceSetting() {
      let self = this;
      // console.log(self)
      this.setting = {
        inline: true, // 设置内敛模式
        menubar: false, // 隐藏菜单栏
        autoresize_on_init: false,
        height: 1500,
        toolbar:
          "code undo redo restoredraft |  pastetext | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | \
        　　　　styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
        　　　　table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs |",
        //工具栏，根据需要写对应的工具名称，顺序及分割线等等，这里的最后的 ‘myUpload’ 是下面setup里的自定义插件
        toolbar_mode: "sliding", // 工具栏模式，这里是滑行模式（当屏幕过小时，多余的工具会隐藏，点击更多按钮会出现其他工具，这里配置出现的特效）
        plugins:
          "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools  link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs uploadimage", // 需要用到的功能插件，如链接，列表等等
        language: "zh_CN", // 语言，汉化
        branding: false, // 隐藏品牌，隐藏状态栏中显示的“ Powered by Tiny ”链接
        resize: false, // 是否可以缩放编辑器
        elementpath: false, // 在编辑器底部的状态栏中禁用元素路径。
        fixed_toolbar_container: "#tinymceToolbar", // 可以设置元素选择器指定工具栏嵌套在哪个元素里面
        custom_ui_selector: "body", // 聚焦的元素
        noneditable_noneditable_class: "mceNonEditable", // 使用此选项，您可以指定TinyMCE将使用的类名称，以确定使用noneditable插件时可编辑哪些内容区域。主要用入你想以代码的形式添加某些内容，并给这些内容设置类名，使他们不可编辑，只能整个删除
        init_instance_callback: (editor) => {
          editor.focus(); // 初始化聚焦，让内联模式的编辑器工具显示
        },
        media_live_embeds: true,
        file_picker_types: "media",
        media_live_embeds: true,
        // 上传图片
        images_upload_handler: async function (blobInfo, succFun, failFun) {
          // var that= this
          var file = blobInfo.blob();
          let formData = new FormData();
          //  console.log(formData)
          formData.append("file", file); //此处与源文档不一样
          const { data: res } = await axios.post(
            "sys/oss/uploadImage",
            formData
          );
          if (res.code != 200) {
          } else {
            var url = res.data.url;
            const { data: res1 } = await axios.get(
              "sys/oss/getFile?objectName=" + url.toString()
            );
            if (res1.code != 200) {
            } else {
              succFun(res1.data);
            }
          }
        },
        // 上传视频
        file_picker_callback: function (cb, value, meta) {
          //当点击meidia图标上传时,判断meta.filetype == 'media'有必要，因为file_picker_callback是media(媒体)、image(图片)、file(文件)的共同入口
          if (meta.filetype == "media") {
            //创建一个隐藏的type=file的文件选择input
            let input = document.createElement("input");
            input.setAttribute("type", "file");
            input.onchange = async function () {
              let file = this.files[0]; //只选取第一个文件。如果要选取全部，后面注意做修改
              let formData = new FormData();
              formData.append("file", file);
              self.loading = true;
              await Promise.all([
                axios.post(
                  "sys/oss/uploadVideo", 
                  formData
                ),
                self.getPercent(),
              ]).then(async (res) => {
                let data = res[0].data;
                if (data.code != 200) {
                  //
                } else {
                  self.loading = false;
                  var url = data.data.url;
                  const { data: res1 } = await axios.get(
                    "sys/oss/getFile?objectName=" + url.toString()
                  );
                  if (res1.code != 200) {
                  } else {
                    cb(res1.data, { title: file.name });
                  }
                }
              });
            };
            //触发点击
            input.click();
          } else {
          }
        },
      };
    },
    async getPercent() {
      setTimeout(async () => {
        try {
          let { data: res2 } = await axios.get("sys/percent/percent");
          if (res2.code == 200 && res2.data != 100 && res2.data >= 0) {
            this.percent = res2.data;
            // console.log(this.percent);
            this.getPercent();
          } else {
            if (res2.data == 100) {
              await axios.get("sys/percent/percent/reset");
            } else if (res2.data < 0) {
              await axios.get("sys/percent/percent/reset");
              Message.error("视频上传失败！");
            }
          }
        } catch (error) {}
      }, 1000);
    },
    async add() {
      console.log(this.year, this.quarter);
      const { data: res } = await this.$http.post("quality/evaluate/select", {
        year: this.year,
        quarter: this.quarter,
      });
      console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data;
        this.isSelect = true;
        this.ReportVisible = false;
      }

      // this.isSelect = !this.isSelect;
      // this.ReportVisible = false;
    },
    async sub() {
      const { data: res } = await this.$http.post("sys/news/info/addNews", {
        titleStatus: 1,
        content: Base64.encode(this.content),
        year: this.year,
        quarter: this.quarter,
        title: this.title,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("公告创建成功");
        this.$router.push("/notice");
      }
      // if (this.checkedPro.length < 2) {
      //   this.$message.error("至少选择两个项目");
    },
  },
  //  checkReport(){
  //     console.log(this.report)
  //   },
};
</script>
<style lang='less' scoped>
.el-input {
  margin-top: 20px;
  width: 50%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.tinymce-editor {
  padding: 20px;
  position: relative;
  .editor {
    padding: 10px;
    border: 1px solid #ddd;
    height: 410px;
    font-size: 16px;
    line-height: 1.4;
    overflow-y: scroll;
  }
}
.btn {
  margin-left: 2%;
}
.el-textarea {
  width: 90%;
  margin: 30px 0 0 30px;
  display: block;
}
.el-button {
  margin: 20px 40px;
}
.active {
  display: none;
}
.select {
  //background: red;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  // display: flex;
  // justify-content: space-around;
  // flex-wrap: nowrap
  span {
    display: inline-block;
    margin: 0 40px;
  }
  .el-button {
    display: block;
    margin: 50px auto 0;
  }
}
.el-radio {
  margin-bottom: 20px;
}
</style>