<template>
  <div>
      <el-card>
          <!-- <el-button type="primary">添加项目</el-button> -->
          <el-table
            :data="productInfoList"
            border
            size="mini"
            style="width: 100%"
          >
          <el-table-column label="年份" width="120">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.year"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="上半年或者下半年" width="140">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.quarter"
                ></el-input>
              </template>
            </el-table-column>
              <el-table-column label="项目名称" width="160">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.prodName"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="排序" width="160">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.orderStatus"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="试剂品牌" width="160">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.reagentBrandTitle"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="检测方法" width="160">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkMethodTitle"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作方法或参考值（*）" width="160">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.operationMethodTitle"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="仪器及型号或质控品LOT：22B1检测结果（%）" width="170">
              <template slot-scope="scope">
                <el-input type="text" v-model="scope.row.modelTitle"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="质控品LOT:21A1检测结果(U/L)或质控品LOT：22B2检测结果（%）" width="280">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult1Title"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="质控品LOT:21A2检测结果(mmol/L)或质控品LOT：22B3检测结果（%）" width="280">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult2Title"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="是否生效">
              <template slot-scope="scope" >
                <el-switch v-model="scope.row.status" active-value="1"
    inactive-value="0" > </el-switch>
              </template>
            </el-table-column>
          </el-table>
          <el-button type="primary" @click="sub">确认创建</el-button>
      </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
        productInfoList:[
            {
                year:'',
                quarter:'',
                prodName:'',
                reagentBrandTitle:'试剂品牌',
                checkMethodTitle:'检测方法',
                operationMethodTitle:'操作方法',
                modelTitle:'仪器及型号',
                checkResult1Title:'质控品LOT:21A1 检测结果(mmol/L)',
                checkResult2Title:'质控品LOT:21A2 检测结果(mmol/L)',
                status:'1',
            }
        ]
    };
  },
  methods:{
      async sub(){
          const {data:res} = await this.$http.post('product/info/info/insert',
          this.productInfoList[0])
          if(res.code!=200){
              this.$message.error(res.message)
          }else{
              this.$message.success("创建成功")
              this.$router.push('/creatprolist')
          }
      }
  }
};
</script>
<style lang='less' scoped>
.el-button{
  margin: 20px 0 0 20px;
}
</style>