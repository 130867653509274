<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in MessageList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="goExit(item.id,item.variable)">{{item.templateConetnt}}</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
    MessageList: [
        // { title: "2021年下半年质评用户数据表" },
      ],
    };
  },
  created() {
    this.getMessageList();
  },
  methods: {
    // 获取短信模板
    async getMessageList() {
      const { data: res } = await this.$http.post("sys/sms/get/template");
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.MessageList = res.data
      }
    },
    // 跳转编辑短信消息
    goExit(id,variable) {
      this.$router.push({
        path: "/messageedit",
        query: {
          id:id,
          variable:variable
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>