<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in qualitqyList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="goList(item.year,item.quarter)">{{ item.year + "年" + item.quarter + item.title }}</span>
        </div>
        
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- <el-button type="primary">创建上报表</el-button> -->
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      qualitqyList: [
      ],
    };
  },
  created(){
    this.getQualitqyList()
  },
  methods:{
    // 获取用户列表
    async getQualitqyList(){
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: '质评活动信息表'
        titleCode:'5'
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.qualitqyList = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getQualitqyList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getQualitqyList();
    },
    // 查看详情
    goList(year,quarter){
      this.$router.push({
        path:'/qualitylist',
        query:{
          year:year,
          quarter:quarter
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
</style>