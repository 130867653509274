<template>
  <div>
    <el-card>
      <el-input
        v-model="qualityControlPerson"
        placeholder="请输入要查找的联系人"
      ></el-input>
      <el-input v-model="mobile" placeholder="请输入要查找的手机号"></el-input>
      <el-input
        v-model="username"
        placeholder="请输入要查找的用户名"
      ></el-input>
      <el-input
        v-model="unitName"
        placeholder="请输入要查找的单位名称"
      ></el-input>
      <el-button type="primary" @click="query">查询</el-button>
      <el-button type="danger" @click="deleteUser">删除</el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="laboratoryCode"
          label="实验室编号"
          min-width
        ></el-table-column>
        <el-table-column
          prop="username"
          label="用户名"
          min-width
        ></el-table-column>
        <el-table-column
          prop="unitName"
          label="单位"
          width="250"
        ></el-table-column>
        <el-table-column prop="laboratoryName" label="科室"></el-table-column>
        <el-table-column
          prop="qualityControlPerson"
          label="联系人"
        ></el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column
          prop="qualityContPerMail"
          label="联系人邮箱"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="qualityContPerAddress"
          label="地址"
          width="400"
        ></el-table-column>
        <el-table-column prop="password" label="密码"></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <el-dialog title="修改密码" :visible.sync="passwordVisible">
        <el-form>
          <el-form-item label="新密码：">
            <el-input v-model="newPassword"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordVisible = false">取 消</el-button>
          <el-button type="primary" @click="UpdatePassword">确 定</el-button>
        </span>
      </el-dialog>
      <el-button type="primary" @click="exportData">导出数据</el-button>
      <!-- <el-button type="primary">导入数据</el-button> -->
      <el-button type="primary" @click="goBlack">拉入黑名单</el-button>
      <el-button type="primary" @click="UpdateVisible">更改密码</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qualityControlPerson: "",
      unitName: "",
      username: "",
      mobile: "",
      total: 0,
      // 选中的用户id
      checkUserid: [],
      currPage: 1,
      totalSize: 10,
      // 所有用户列表数据
      tableData: [],
      passwordVisible: false,
      newPassword: "",
      checkPassword: "",
    };
  },
  computed: {},
  created() {
    this.getUserList();
    // console.log(this.checkUserid);
  },
  methods: {
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post("sys/user/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        status: "1",
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },
    // 查询用户
    async query() {
      const { data: res } = await this.$http.post("sys/user/list", {
        username: this.username,
        mobile: this.mobile,
        unitName: this.unitName,
        qualityControlPerson: this.qualityControlPerson,
        status: "1",
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 删除用户
    async deleteUser() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      }
      //  else if (this.checkUserid.length > 1) {
      //   this.$message.error("只能选中一个删除");
      // }
      else {
        const confirm = await this.$confirm("确定删除该项目吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          const { data: res } = await this.$http.post(
            "sys/user/delete",
            this.checkUserid
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("删除成功");
            this.getUserList();
          }
        }
      }
    },
    // 拉入黑名单
    async goBlack() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      } else {
        const confirm = await this.$confirm("确定将用户拉入黑名单吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          const { data: res } = await this.$http.post(
            "sys/user/updateUserStatus",
            {
              id: this.checkUserid,
              status: "0",
            }
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("成功拉入黑名单");
            this.getUserList();
          }
        }
      }
    },
    // 修改密码
    async UpdatePassword() {
      console.log(this.checkPassword);
      const confirm = await this.$confirm("确定该用户的密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirm == "confirm") {
        this.checkPassword.password = this.newPassword;
        const { data: res } = await this.$http.post(
          "person/update",
          this.checkPassword
        );
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("修改用户密码成功");
          this.getUserList();
          this.passwordVisible = false;
        }
      }
    },
    UpdateVisible() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      } else if (this.checkUserid.length > 1) {
        this.$message.error("只能选中一个用户修改");
      } else {
        this.passwordVisible = true;
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getUserList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getUserList();
    },
    // 导出数据
    async exportData() {
      const confirm = await this.$confirm("确定将用户列表导出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirm == "confirm") {
        window.open(this.$http1 + "sys/user/exportUserList");
      }
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.checkUserid = val.map((item) => {
        return item.id;
      });
      this.checkPassword = val[0];
      // console.log(val);
      // console.log(this.checkUserid);
    },
  },
};
</script>
<style lang="less" scoped>
.el-button {
  margin: 20px;
}
.el-input {
  width: 200px;
  margin-right: 30px;
}
</style>
