<template>
  <div>
    <el-card>
      <!-- <el-input v-model="year" placeholder="请输入要查找的年份"></el-input>
      <el-input
        v-model="quarter"
        placeholder="请输入要查找的年份季度"
      ></el-input>
      <el-input
        v-model="firstKey"
        placeholder="请输入要查找的配置名称"
      ></el-input>
      <el-button type="primary" @click="query">查询</el-button> -->
      <div class="btn1">
        <el-upload
          :action="uploadImg()"
          :headers="myHeaders"
          :show-file-list="false"
          :on-success="handleImgSuccess"
        >
          <el-button type="success">上传图片</el-button>
        </el-upload>

        <el-upload
          :action="uploadVideo()"
          :headers="myHeaders"
          :show-file-list="false"
          :on-success="handleVideoSuccess"
          :before-upload="beforeUploadVideo"
        >
          <el-button type="primary">上传视频</el-button>
        </el-upload>

        <el-button type="danger" @click="deletePro">删除</el-button>
      </div>

      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="id" min-width></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          min-width
        ></el-table-column>
        <el-table-column prop="url" label="文件名"></el-table-column>
        <el-table-column prop="realUrl" label="地址">
          <!-- <template slot-scope="scope">
          <img :src="scope.row.realUrl" style="width:100px;height:100px">
        </template> -->
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img :src="scope.row.realUrl" style="width: 100px; height: 100px" />
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- <el-button type="primary" @click="Export">导出数据</el-button>
      <el-button type="primary">导入数据</el-button>
      <el-button type="primary" @click="goBlack">拉入黑名单</el-button> -->
      <el-dialog
        title="内容创建"
        :visible.sync="proVisible"
        width="60%"
        class="dialog"
      >
        <div>
          <el-input
            v-for="(item, index) in CreateInput"
            :key="index"
            v-model="item.paramValue"
            disabled
          ></el-input>
        </div>
        <div>
          <el-input
            v-for="(item, index) in InputList"
            :key="index"
            v-model="inputModel[index]"
          ></el-input>
        </div>
        <el-button type="primary" @click="add">添加项目</el-button>
        <el-button type="primary" @click="sub">删减项目</el-button>
        <span slot="footer" class="dialog-footer">
          <el-button @click="proVisible = false">取 消</el-button>
          <el-button type="primary" @click="goreport()">确认创建</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { base } from "../../main.js";
var token = window.localStorage.getItem("token");
export default {
  data() {
    return {
      proVisible: false,
      id: "",
      firstKey: "",
      year: "",
      quarter: "",
      total: 0,
      currPage: 1,
      totalSize: 10,
      // 所有用户列表数据
      tableData: [],
      InputList: 1,
      inputModel: [],
      CreateInput: [],
      //视频上传进度数据
      percent: 0,
      // 选中的项目id
      checkUserid: [],
      checkPro: "",
      // 请求头
      myHeaders: { accessToken: token },
    };
  },

  created() {
    this.getUserList();
    // this.$loading({
    //   text: '正在上传中，请勿点击其他操作，上传进度：' + '' + '%'
    // })
  },
  methods: {
    add() {
      this.InputList = this.InputList + 1;
    },
    sub() {
      if (this.InputList <= 1) {
        this.$message.error("至少添加一个项目");
      } else {
        this.InputList = this.InputList - 1;
      }
    },
    uploadImg() {
      return  this.$http1+'sys/oss/uploadImage';
    },
    uploadVideo() {
      return this.$http1+'sys/oss/uploadVideo';
    },
    beforeUploadVideo() {
      // const loading = this.$loading({
      //   text: "正在上传中，请勿点击其他操作，上传进度：" + this.percent + "%",
      // });
      // loading.setText(
      //   "正在上传中，请勿点击其他操作，上传进度：" + this.percent + "%"
      // );
      this.getPercent();
    },
    handleVideoSuccess() {
      this.$loading().close();
      this.$message.success("上传成功");
      this.getUserList();
    },
    handleImgSuccess() {
      this.$message.success("上传成功");
      this.getUserList();
    },
    async getPercent() {
      const loading = this.$loading({
        text: "正在上传中，请勿点击其他操作，上传进度：" + this.percent + "%",
      });
      setTimeout(async () => {
        try {
          let { data: res2 } = await this.$http.get("sys/percent/percent");
          // console.log(res2.data, "diaoyong percent");
          if (res2.code == 200 && res2.data != 100 && res2.data >= 0) {
            this.percent = res2.data;
            loading.setText(
              "正在上传中，请勿点击其他操作，上传进度：" + this.percent + "%"
            );
            // console.log(this.percent);
            this.getPercent();
          } else {
            if (res2.data == 100) {
              await this.$http.get("sys/percent/percent/reset");
              loading.close()
            } else if (res2.data < 0) {
              await this.$http.get("sys/percent/percent/reset");
              Message.error("视频上传失败！");
              loading.close()
            }
          }
        } catch (error) {}
      }, 1000);
    },
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post("sys/oss/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },
    // 查询用户
    async query() {
      const { data: res } = await this.$http.post("sysConfig/config/list", {
        year: this.year,
        quarter: this.quarter,
        currPage: this.currPage,
        totalSize: this.totalSize,
        firstKey: this.firstKey,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getUserList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getUserList();
    },
    // 删除数据
    async deletePro() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      }
      //  else if (this.checkUserid.length > 1) {
      //   this.$message.error("只能选中一个删除");
      // }
      else {
        const confirm = await this.$confirm("确定删除该项目吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          const { data: res } = await this.$http.post(
            "sys/oss/delete",
            this.checkUserid
          );
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("删除成功");
            this.getUserList();
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.checkUserid = val.map((item) => {
        return item.id;
      });
      // this.checkPro = val[0].id;
      // console.log(val);
      // console.log(this.checkUserid);
    },
  },
};
</script>
<style lang="less" scoped>
.btn1 {
  margin: 20px 0;
  float: left;
  div {
    display: inline-block;
  }
}
.el-button {
  margin-left: 20px;
}
.el-input {
  width: 200px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.dialog {
  .el-button {
    margin-right: 20px;
    margin-left: 0;
  }
}
</style>
