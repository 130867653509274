<template>
  <div>
    <el-card>
      <el-table :data="qualitytable" style="width: 100%" border>
        <el-table-column prop="laboratoryCode" label="实验编号" width="80">
        </el-table-column>
        <el-table-column prop="unitName" label="单位名称" width="180">
        </el-table-column>
        <el-table-column prop="laboratoryName" label="实验室名称" width="150">
        </el-table-column>
        <el-table-column
          prop="qualityControlPerson"
          label="质控联系人"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="mobile" label="联系人电话" width="150">
        </el-table-column
        >v
        <el-table-column
          prop="qualityContPerMail"
          label="联系人邮箱"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="qualityContPerAddress" label="地址" width="150">
        </el-table-column>
        <el-table-column
          v-for="(item, index) in qualitytable[0].prodInfoList"
          :key="index"
          :label="item.prodName"
          width="150"
        >
          <template slot-scope="scope">
            <span>
              {{scope.row.prodInfoList[index].status == '1' ? '√':'×'}}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20, 50]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button type="primary" @click="exportData">导出数据</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: "",
      quarter: "",
      total: 0,
      currPage: 0,
      totalSize: 10,
      // 质评用户数据表
      qualitytable: [
      ],
      // 检查项目列表
      project: [
      ],
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.getQualityList();
  },
  methods: {
    // 获取用户列表
    async getQualityList() {
      const { data: res } = await this.$http.post("quality/data/user/list", {
        year: this.year,
        quarter: this.quarter,
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.qualitytable = res.data.records;
        this.total = res.data.total;
      }
      console.log(res);
    },
    async exportData(){
      const confirm = await this.$confirm("确定将用户列表导出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) =>err);
      if(confirm == "confirm"){
      window.open(this.$http1+'quality/data/user/export?year='+this.year+'&quarter='+this.quarter)
      }
    },
     // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getQualityList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getQualityList();
    },
  },
};
</script>
<style lang='less' scoped>
.el-table {
  margin-bottom: 20px;
  // text-align: center;
  /deep/ .el-table__cell {
    text-align: center;
  }
}
</style>