<template>
  <div>
    <el-card>
      <!-- <div class="notice_inner" v-for="item in explainList" :key="item.index">
        <div class="title" @click="godetails(item.id)">
          <i class="el-icon-caret-right"></i>
          <span>{{ item.title }}</span>
        </div>

        <el-button type="danger" @click="deleteExplain(item.id)">删除</el-button>
      </div> -->
      <div class="carditem">
        <div class="details" v-for="item in explainList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="godetails(item.id)">{{item.title }}</span>
          <el-button type="danger" @click="deleteExplain(item.id)">删除</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button class="btn" type="primary" @click="goCreatExplain">创建操作说明</el-button>
      <!-- <div class="carditem">
        <div class="details" v-for="item in explainList" :key="item.index">
          <span @click="godetails(item.id)">{{ item.title }}</span>
          
        </div>
      </div> -->
      <!-- <div class="footer">
        <el-input
          class="ex_title"
          type="text"
          placeholder="请输入操作说明标题"
          v-model="title"
        ></el-input>
        <el-input resize="none" type="textarea" :rows="15" v-model="textarea">
        </el-input>
        <div class="upload">
          <el-button type="primary" @click="addExplain">创建</el-button>
        </div>
      </div> -->
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
       total: 0,
      currPage: 1,
      totalSize: 10,
      explainList: [],
      title: "",
      textarea: "",
    };
  },
  created() {
    this.getExplainList();
  },
  methods: {
    // 获取操作说明列表
    async getExplainList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        titleStatus: 2,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.explainList = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },    
     // 删除操作说明
     async deleteExplain(id){
      const confirm = await this.$confirm("确定删除该条操作说明吗？","提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err =>err);
      if(confirm == 'confirm'){
        const {data :res} =await this.$http.post('sys/news/info/delete',{
         id:id
        })
        if(res.code !=200){
          this.$message.error(res.message)
        }else{
          this.$message.success('已成功删除该条操作说明')
          this.getExplainList()
        }
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getExplainList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getExplainList();
    },
    goCreatExplain(){
      this.$router.push({
        path:'/createxplain'
      })
    },
    // 创建操作说明
    // async addExplain() {
    //   const { data: res } = await this.$http.post("sys/news/info/addNews", {
    //     titleStatus: 2,
    //     content: this.textarea,
    //     title: this.title,
    //   });
    //   if (res.code != 200) {
    //     this.$message.error(res.message);
    //   } else {
    //     this.$message.success("操作说明创建成功");
    //     this.title = ''
    //     this.textarea= ''
    //     // this.$router.push("/news");
    //     this.getExplainList();
    //   }
    // },
    // 操作说明详情
    godetails(id) {
      this.$router.push({
        path: "/createxplain",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-textarea {
  width: 90%;
  margin: 30px 0 0 30px;
}
.ex_title {
  width: 50%;
  margin-left: 300px;
}
.notice_inner {
  margin-bottom: 10px;
  padding: 0 100px;
  display: flex;
  // justify-content: space-between;
  line-height: 25px;
  // font-size: 14px;
  // margin-bottom: 20px;
  .title {
    margin-right: 150px;
    cursor: pointer;
  }
  i {
    display: inline-block;
    margin-right: 5px;
    color: #ccc;
    line-height: 27px;
  }
  .el-button {
    padding: 0;
    width: 100px;
    height: 35px;
    // margin-left: 100px;
  }
}
.upload {
  text-align: right;
  .el-button {
    margin: 30px 150px 0 0;
  }
}
.footer{
  margin-top: 150px;
}
</style>