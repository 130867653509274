<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in noticeList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="godetails(item.id,item.year,item.quarter)">{{item.title }}</span>
          <el-button type="danger"  @click="deleteNotice(item.id)">删除</el-button>
        </div>
      </div>
       <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button class="btn" type="primary" @click="goCreatNotice"
        >创建公告</el-button
      >
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      id:'',
      // year: '',
      // quarter: '',
      noticeList: [],
    };
  },
  created(){
      this.getNoticeList()
  },
  methods: {
    async getNoticeList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        titleStatus: 1,
      });
      if(res.code !=200){
        this.$message.error(res.message);
      }else{
        this.noticeList = res.data.records
        this.total = res.data.total;
      }
      // console.log(res)
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getNoticeList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getNoticeList();
    },
    // 删除公告
    async deleteNotice(id){
      const confirm = await this.$confirm("确定删除该条公告吗？","提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err =>err);
      if(confirm == 'confirm'){
        const {data :res} =await this.$http.post('sys/news/info/delete',{
         id:id
        })
        if(res.code !=200){
          this.$message.error(res.message)
        }else{
          this.$message.success('已成功删除该公告')
          this.getNoticeList()
        }
      }
    },
    godetails(id,year,quarter){
      this.$router.push({
        path:'/noticedetails',
        query:{
          id:id,
          year:year,
          quarter:quarter
        }
      })
      
    },
    goCreatNotice(){
      this.$router.push({
        path: '/creatnotice'
      })
    }
  },
};
</script>
<style lang='less' scoped>
</style>