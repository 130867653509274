<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="details" v-for="item in exportList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="goExport(item.year, item.quarter)">{{
            item.year + "年" + item.quarter + item.title
          }}</span>
          
          <!-- <el-button type="danger">删除</el-button> -->
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      exportList: [],
    };
  },
  created() {
    this.getExportList();
  },
  methods: {
    async getExportList() {
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: "质评用户数据表",
        titleCode:'2'
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.exportList = res.data.records;
        this.total = res.data.total;
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getExportList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getExportList();
    },
    goExport(year, quarter) {
      this.$router.push({
        path: "/exportlist",
        query: {
          year: year,
          quarter: quarter,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>