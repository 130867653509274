<template>
  <div>
    <el-card>
      <el-input v-model="year" placeholder="请输入要查找的年份"></el-input>
      <el-input
        v-model="quarter"
        placeholder="请输入要查找的年份季度"
      ></el-input>
      <el-select
        v-model="firstKey"
        placeholder="请选择"
        @change="centerChange"
        clearable
      >
        <el-option
          v-for="item in firstKeyList"
          :key="item.value"
          :value="item.value"
          :label="item.key"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="query">查询</el-button>
      <el-button type="success" @click="add">新增</el-button>
      <el-button type="danger" @click="deletePro">删除</el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="year" label="年份" min-width></el-table-column>
        <el-table-column
          prop="quarter"
          label="年份季度"
          min-width
        ></el-table-column>
        <el-table-column
          prop="firstKey"
          label="配置名称"
          width="200"
        ></el-table-column>
        <el-table-column label="配置值" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.paramValue }}</span>
          </template>
        </el-table-column>
        <el-table-column label="副标题" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.paramKey }}</span>
          </template>
        </el-table-column>
        <el-table-column label="链接" width="400">
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.remark }}</span> -->
            <img :src="scope.row.remark" style="width: 100px; height: 100px" />
          </template>
        </el-table-column>
        <el-table-column label="" min-width>
          <template slot-scope="scope">
            <el-button type="success" @click="updata(scope.row)"
              >更新</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- <el-button type="primary" @click="Export">导出数据</el-button>
      <el-button type="primary">导入数据</el-button>
      <el-button type="primary" @click="goBlack">拉入黑名单</el-button> -->
      <el-dialog
        title="内容创建"
        :visible.sync="proVisible"
        width="60%"
        class="dialog"
        @close="addDiaClose"
      >
        <el-form label-width="150px" :model="formData">
          <el-form-item label="年份：">
            <el-input v-model="formData.year"></el-input>
          </el-form-item>
          <el-form-item label="年份季度：">
            <el-input v-model="formData.quarter"></el-input>
          </el-form-item>
          <el-form-item label="配置名称：">
            <el-select
              v-model="formData.firstKey"
              placeholder="请选择"
              @change="centerChange"
            >
              <el-option
                v-for="item in firstKeyList"
                :key="item.value"
                :value="item.value"
                :label="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="配置值：">
            <el-input v-model="formData.paramValue"></el-input>
          </el-form-item>
          <el-form-item label="副标题：">
            <el-input
              v-model="formData.paramKey"
              placeholder="空间质量评价回报表"
            ></el-input>
          </el-form-item>
          <el-form-item label="链接：">
            <el-input v-model="formData.remark"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="proVisible = false">取 消</el-button>
          <el-button type="primary" @click="goreport()">确认创建</el-button>
        </span>
      </el-dialog>
      <!-- 修改配置 -->
      <el-dialog
        title="修改配置"
        :visible.sync="proVisible1"
        width="60%"
        class="dialog"
        @close="updataDiaClose"
      >
        <el-form label-width="80px" :model="formData">
          <el-form-item label="年份">
            <el-input v-model="formData.year"></el-input>
          </el-form-item>
          <el-form-item label="年份季度">
            <el-input v-model="formData.quarter"></el-input>
          </el-form-item>
          <el-form-item label="配置名称">
            <el-select
              v-model="formData.firstKey"
              placeholder="请选择"
              @change="centerChange"
            >
              <el-option
                v-for="item in firstKeyList"
                :key="item.value"
                :value="item.value"
                :label="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="配置值">
            <el-input v-model="formData.paramValue"></el-input>
          </el-form-item>
          <el-form-item label="副标题：">
            <el-input
              v-model="formData.paramKey"
              placeholder="空间质量评价回报表"
            ></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="formData.remark"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="proVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="goUpdate()">确认修改</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      proVisible1: false,
      remarkAbile: false,
      proVisible: false,
      id: "",
      firstKey: "",
      year: "",
      quarter: "",
      total: 0,
      currPage: 1,
      totalSize: 10,
      // 所有用户列表数据
      tableData: [],
      //表单数据
      InputList: 1,
      inputModel: [],
      CreateInput: [],
      // 选中的项目id
      checkUserid: [],
      firstList: "",
      checkPro: "",
      firstKeyList: [
        { key: "质控中心", value: "qe_centre" },
        { key: "质控品编号1", value: "controlMaterialCode_1" },
        { key: "质控品编号2", value: "controlMaterialCode_2" },
      ],
      formData: {
        year: "",
        quarter: "",
        firstKey: "",
        paramValue: "",
        remark: "",
      },
    };
  },

  created() {
    this.getUserList();
    this.getFirstKeyList();
  },
  methods: {
    add() {
      this.proVisible = true;
    },
    updata(data) {
      this.proVisible1 = true;
      this.formData = data;
    },

    // 获取配置列表
    async getFirstKeyList() {
      const { data: res } = await this.$http.get(
        "sysConfig/config/getFirstKey"
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.firstList = res.data.map((item) => {
          return {
            value: item,
          };
        });
        // console.log(this.firstList);
      }
    },
    // 获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post("sysConfig/config/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      // console.log(res);
    },
    // 查询用户
    async query() {
      const { data: res } = await this.$http.post("sysConfig/config/list", {
        year: this.year,
        quarter: this.quarter,
        currPage: this.currPage,
        totalSize: this.totalSize,
        firstKey: this.firstKey,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    centerChange(e) {
      if (e == "qe_centre") return (this.remarkAbile = true);
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getUserList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getUserList();
    },
    // 对话点击确定创建项目
    async goreport() {
      //   if (!this.inputModel[0]) {
      //     this.$message.error("添加项目为空");
      //   } else {
      const { data: res } = await this.$http.post(
        "sysConfig/config/insert",
        this.formData
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("创建成功");
        this.proVisible = false;
        // this.getUserList()
        this.query()
      }
      //   }
    },
    // 对话框点击修改项目
    async goUpdate() {
      const { data: res } = await this.$http.post(
        "sysConfig/config/update",
        this.formData
      );
      console.log(this.formData);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("修改成功");
        this.proVisible1 = false;
        // this.getUserList()
        this.query()
      }
    },
    // 关闭创建对话框清空
    addDiaClose() {
      this.formData = {};
    },
    // 关闭修改对话框
    updataDiaClose() {
      this.getUserList();
      this.query();
    },
    // 删除数据
    async deletePro() {
      if (this.checkUserid.length == 0) {
        this.$message.error("未选中数据");
      }
      // else if (this.checkUserid.length > 1) {
      //   this.$message.error("只能选中一个删除");
      // }
       else {
        const confirm = await this.$confirm("确定删除该项目吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirm == "confirm") {
          // const { data: res } = await this.$http.post("sysConfig/delete", {ids: this.checkUserid});

          const { data: res } = await this.$http.post("sysConfig/delete",this.checkUserid);
          if (res.code != 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("删除成功");
            this.getUserList();
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.checkUserid = val.map((item,index) => {
        return item.id;
      });
      // this.checkPro = val[0].id;
      console.log(val);
      console.log(this.checkUserid);
    },
  },
};
</script>
<style lang="less" scoped>
.el-button {
  margin-left: 20px;
}
.el-input {
  width: 200px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.dialog {
  .el-button {
    margin-right: 20px;
    margin-left: 0;
  }
}
</style>
