<template>
  <div>
      <el-card>
      <div class="carditem">
        <div class="details" v-for="item in newsList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <span @click="godetails(item.id)">{{item.title }}</span>
          <el-button type="danger" @click="deleteNews(item.id)">删除</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="totalSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-button class="btn" type="primary" @click="goCreatNews">创建新闻</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      id:'',
      newsList:[]
    };
  },
  created(){
    this.getNewsList()
  },
  methods:{
     async getNewsList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        titleStatus: 0,
      });
      if(res.code !=200){
        this.$message.error(res.message);
      }else{
        this.newsList = res.data.records
        this.total = res.data.total;
      }
      
      // console.log(res)
    },
    // 删除新闻
     async deleteNews(id){
      const confirm = await this.$confirm("确定删除该条新闻吗？","提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err =>err);
      if(confirm == 'confirm'){
        const {data :res} =await this.$http.post('sys/news/info/delete',{
         id:id
        })
        if(res.code !=200){
          this.$message.error(res.message)
        }else{
          this.$message.success('已成功删除该新闻')
          this.getNewsList()
        }
      }
    },
    // 每页显示条数变化
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getNewsList();
    },
    // 当前页变化
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getNewsList();
    },
    godetails(id){
      this.$router.push({
        path:'/creatnews',
        query:{
          id:id
        }
      })      
    },
    goCreatNews(){
      this.$router.push({
        path:'/creatnews',
      })
    }
  }
};
</script>
<style lang='less' scoped>
</style>